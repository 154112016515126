import React from 'react'
import useMobile from '../../../shared/hooks/useMobile'
import CardProduct from '../../../shared/components/atoms/card-product/card-product'
import useProduct from '../../../shared/hooks/useProduct'
import BreadCrumb from '../../../shared/components/atoms/breadcrumb/breadcrumb'
import './product-content.scss'

const ProductContent = (props) => {

  const { setModalActive, params } = props
  const { banner: bannerInfo, productos: productInfo } = params
  const [getFilterNewsProduct] = useProduct()
  const isMobile = useMobile();

  return (
    <div className="f-product-container">
      <div className="f-product-header">

        <figure className="f-product-header-img">
          <img src={bannerInfo.banners[isMobile ? 1 : 0].file.url} alt={bannerInfo.banners[isMobile ? 1 : 0].description} />
        </figure>

        <div className="f-breadcrum-position"><BreadCrumb mobile={isMobile} /></div>

        {!isMobile && <div className="f-product-title-content">
          <h1 className="f-title">{bannerInfo?.tituloBanner}</h1>
          <p className="f-description">{bannerInfo?.descripcionBanner?.descripcionBanner}</p>
        </div>}

      </div>
      <div className="f-product-grid-contianer">

        {isMobile && <div className="f-product-title-content">
          <h1 className="f-title">{bannerInfo?.tituloBanner}</h1>
          <p className="f-description">{bannerInfo?.descripcionBanner?.descripcionBanner}</p>
        </div>}

        <div className="f-product-grid">
          {
            getFilterNewsProduct(productInfo).map((product, key) => {
              return (
                <CardProduct className="f-card-grid" product={product} key={key} />
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default ProductContent;