import React from 'react'
import Layout from '../../shared/components/organisms/layout/layout'
import ProductContent from '../../components/organisms/product-content/product-content'
import SEO from "../../shared/components/atoms/seo/seo"

const ProductTemplate = props => {

  const { pageContext } = props
  // console.log("pageContext", pageContext)
  // send active modal to layout
  const [modalActive, setModalActive] = React.useState(null);

  return (
    <Layout activeMenu={1} modalActive={modalActive}>
      <SEO
        title={pageContext.metaTitulo ? pageContext.metaTitulo : ''}
        description={pageContext.metaDescripcion ? pageContext?.metaDescripcion?.metaDescripcion : ''}
        slug={pageContext.slug ? pageContext.slug: ''}
        lang="ES"
      />
      <ProductContent params={pageContext} setModalActive={setModalActive} />
    </Layout>
  )
}

export default ProductTemplate
